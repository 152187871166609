.loading-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .loading-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .loading-exit {
    opacity: 1;
  }
  .loading-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  